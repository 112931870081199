import React from "react"

const Execution = (props) =>
{
    return (
        <div id="execution" className="fieldElements">
            <label>Type JS code</label>
            <textarea placeholder="alert(1)"></textarea>
        </div>
    )
}

export default Execution
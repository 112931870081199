import React from "react";
import { queues } from "../data/queues";
import { newDebug } from "../functions/monitor";

const FireEvent = (props) => {
  const change = (e) => {
    let value = e.target.value;
    let wsh = document.getElementsByClassName("wsh");
    if (value.length > 0) {
      Object.values(wsh).map((w) => (w.style.display = "block"));
      newDebug(`selecting event <code>${value}</code>`, "default");
    } else {
      Object.values(wsh).map((w) => (w.style.display = "none"));
      newDebug("Deselect event", "default");
    }
  };

  return (
    <div id="fire-event-on-shop" className="fieldElements">
      <div className="alert-warning reset-section-padding">
        <b>Warning</b>: Some events will be in queue added to be processed.
        Because event has waiting mode.{" "}
        <a
          href="https://de.wikipedia.org/wiki/Warteschlange_(Datenstruktur)"
          target="_blank"
        >
          Learn more
        </a>
      </div>

      <label>Select event</label>
      <select onChange={change}>
        <option value={""}>Select event</option>
        {Object.keys(queues).map((name, k) => (
          <optgroup
            label={name.charAt(0).toUpperCase() + name.slice(1)}
            key={k}
          >
            {queues[name].map((q, key) => (
              <option value={q.id} key={key}>
                {q.event}
              </option>
            ))}
          </optgroup>
        ))}
      </select>

      <div className="wsh" id="wsh" style={{ display: "none" }}>
        <label>Communication protocol</label>
        <select>
          <option value="standart">Standard HTTP</option>
          <option value="websocket" disabled>
            Websocket
          </option>
        </select>
      </div>

      {/* <div className="wsh" id="wsh" style={{ display: "none" }}>
        <label>Shop provider</label>
        <select>
          <option value="shopify">SHOPIFY</option>
          <option value="wix" disabled>WIX</option>
          <option value="shopware" disabled>Shopware</option>
          <option value="jtl" disabled>JTL</option>
        </select>
      </div> */}

      <div className="wsh" id="wsh" style={{ display: "none" }}>
        <label>What should in Shopify side happen when event fired?</label>
        <select>
          <option value="alert">alert('Catched')</option>
        </select>
      </div>

      <div className="wsh" id="wsh" style={{ display: "none" }}>
        <label>What should here by our side happen when event fired?</label>
        <select>
          <option value="alert">alert('Targeted')</option>
        </select>
      </div>
    </div>
  );
};

export default FireEvent;

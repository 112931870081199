import React from "react";

function Performance() {
  return (
    <div className="innen-section performance-section" id="performance-section">
      <div className="output-title">Performance</div>
      {/* <div className="noData">No data to process</div> */}
      <div className="innen-content">
        <div className="nested-section">
          <div className="inline-row" style={{justifyContent: 'center'}}>
            <span className="key">Execute time</span>
          </div>
        </div>
        <div className="nested-section time">
          <div className="performanceTime" id="performanceTime">
            <div className="col start">
              <span>Start (in ms)</span>
              <div id="startTime">0</div>
            </div>
            <div className="col end">
              <span>End (in ms)</span>
              <div id="endTime">0</div>
            </div>
            <div className="col sum">
              <span>Sum (in ms)</span>
              <div id="sumTime">0</div>
            </div>
          </div>
        </div>
        <div className="nested-section countWebsockets">
          <div className="inline-row">
            <span className="key">Total websocket connections</span>
            <span className="value" id="totalWebsocketConnections">0</span>
          </div>
        </div>
        <div className="nested-section countWebsockets">
          <div className="inline-row">
            <span className="key">Total jobs</span>
            <span className="value" id="totalJobs">0</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Performance;

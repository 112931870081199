import React from "react";
var tot = React.createRef();
const getUrls = () => {
  const shop = localStorage.getItem("store") || null;
  if (shop) {
    const url =
      "wss://playground.superflow.workers.dev/channels/visits/" + shop;

    const webSocket = new WebSocket(url);

    const wrappper = document.querySelector("#b-urls");
    var list = document.querySelector("#b-urls ul");
    webSocket.addEventListener("message", (event) => {
      list.innerHTML += `<li class="log debug default">${event.data}</li>`;
      wrappper.scrollTo(0, list.scrollHeight);
    });
  }
};

const countVisitors = () => {
  const shop = localStorage.getItem("store") || null;
  if (shop) {
    const url = "wss://playground.superflow.workers.dev/channels/count/" + shop;
    const webSocket = new WebSocket(url);
    webSocket.addEventListener("message", (event) => {
      tot.current.innerHTML = event.data;
    });
  }
};

function Brodcast() {
  setTimeout(function () {
    countVisitors();
    getUrls();
  }, 1000);

  // var countTotalVisitors = countVisitors()
  return (
    <div className="output brodcast">
      <div className="output-title">
        <div className="b-title">
          <div className="liveSymbole"></div>
          <span>Analytics</span>
        </div>
        <div>
          live visitors:{" "}
          <span id="totalVisitors" ref={tot}>
            0
          </span>
        </div>
      </div>
      <div className="section-content quques-wrapper" id="quques-wrapper">
        <div id="q-log"></div>
        {/* <div className="noData" style={{ height: "100%" }}>
          No Brodcast availabe
        </div> */}
        <div className="b-urls" id="b-urls">
          <ul className="urls"></ul>
        </div>
      </div>
    </div>
  );
}

export default Brodcast;

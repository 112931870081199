import React from "react";

function Right() {
  return (
    <div className="header-section modules">
      <div className="header-title">Tunnels, Protocols & Modes</div>
      <div className="header-content">
        <div className="headerCheckBoxes">
          <div className="inp">
            <input type="checkbox" value={""} id="websocket" disabled />
            <label htmlFor="websocket" style={{ color: "#777" }}>
              {" "}
              Websocket
            </label>
          </div>
          <div className="inp">
            <input type="checkbox" value={""} id="cf_worker" checked disabled />
            <label htmlFor="cf_worker" style={{ color: "#777" }}>
              {" "}
              CF Worker
            </label>
          </div>
          <div className="inp">
            <input type="checkbox" value={""} id="cf_kv" checked disabled />
            <label htmlFor="cf_kv" style={{ color: "#777" }}>
              {" "}
              CF KV
            </label>
          </div>
          <div className="inp">
            <input type="checkbox" value={""} id="cf_d1" disabled />
            <label htmlFor="cf_d1" style={{ color: "#777" }}>
              {" "}
              CF D1
            </label>
          </div>
          <div className="inp">
            <input
              type="checkbox"
              value={""}
              id="cf_durable"
              checked
              disabled
            />
            <label htmlFor="cf_durable" style={{ color: "#777" }}>
              {" "}
              CF Durableobject
            </label>
          </div>
          <div className="inp">
            <input type="checkbox" value={""} id="queue" checked disabled />
            <label htmlFor="queue" style={{ color: "#777" }}>
              {" "}
              Queue
            </label>
          </div>
          <div className="inp">
            <input type="checkbox" value={""} id="jobs_listeners" disabled />
            <label htmlFor="jobs_listeners" style={{ color: "#777" }}>
              {" "}
              Jobs Listener
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Right;

import React from "react";
import Brodcast  from "./Brodcast";
var totalCatches = React.createRef();
var list = React.createRef()
var start = 0
const catchEvents = () => {
  const shop = localStorage.getItem("store") || null;
  if (shop) {
    const url = "wss://playground.superflow.workers.dev/channels/events/" + shop;
    const webSocket = new WebSocket(url);
    const wrappper = document.querySelector("#c-urls");
    // var list = document.querySelector("#c-urls ul");
    webSocket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data)
      ++start
      totalCatches.current.innerHTML = start

      const li = document.createElement('li')
      li.setAttribute('class', `log debug default ${data.style}`)
      if (data.style.includes('blink')) {
        li.setAttribute('style', `animation: blinker .5s linear`)
      }
      li.innerText = data.message
      list.current.appendChild(li);
    });
  }
}

function Queue() {
  

  catchEvents()

  return (
    <div className="section-part results-section event-catcher-section">
      <Brodcast />
      <div className="output">
        <div className="output-title">
        <div className="b-title">
          <div className="liveSymbole"></div>
          <span>Event Catcher</span>
        </div>
          <div>
            Total Catches: <span id="totalevent-catchers" ref={totalCatches}>0</span>
          </div>
        </div>
        <div className="section-content quques-wrapper" id="quques-wrapper">
          <div id="q-log"></div>
          <div className="b-urls" id="c-urls">
            <ul ref={list}></ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Queue;

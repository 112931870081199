import { outputConsole, performanceConsole as pConsole } from "./monitor";
import { post } from "./api";

export const execute = async (e) => {
  e.preventDefault();
  const output = outputConsole();
  const performanceConsole = pConsole();
  // var now = performance.now();
  var now = new Date().getTime();
  performanceConsole.time.set({
    start: now,
    end: 0,
    sum: 0,
  });
  performanceConsole.setTotalWebscoketConenctions(10);
  performanceConsole.setTotalJobs(5);
  // const data = await post("commands/list", { shop_id: "asdasdasd23" });
  const data = await post("commands/create", {
    shop_id: "asdasdasd23",
    body: {
      command: 'bla bla'
    },
  });

  console.log(data);

  performanceConsole.time.set({
    start: now,
    end: new Date().getTime(),
  });
};

import React from "react";

export function LogDetail() {
  return (
    <div className="offcanvas-wrapper">
      <div className="offcanvas">
        <div className="offcanvas-header">
          Header
          <div className="offcanvasClose" id="offcanvasClose">
            close
          </div>
        </div>
        <div className="offcanvas-body">Body</div>
      </div>
      <div className="overlay"></div>
    </div>
  );
}

export default LogDetail;

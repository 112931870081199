import React from "react";

import Logo from '../header/Logo'
import Center from '../header/Center'
import Right from '../header/Right'
import Modules from '../header/Modules'

function Header() {


  return (
    <div className="header">
      <Logo />
      <Center />
      <Modules />
      <Right />
    </div>
  );
}

export default Header;

export const formatTime = (time) => {
  return time < 10 ? "0" + time : time;
};
export const logTime = () => {
  var date = new Date(),
    hours = formatTime(date.getHours()),
    minutes = formatTime(date.getMinutes()),
    seconds = formatTime(date.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
};

export const performanceConsole = () => {
  var performance = document.getElementById("performance-section"),
    totalWebsocketConnections = performance.querySelector(
      "#totalWebsocketConnections"
    ),
    totalJobs = performance.querySelector("#totalJobs"),
    performanceTime = document.getElementById("performance-section"),
    start = performanceTime.querySelector("#startTime"),
    end = performanceTime.querySelector("#endTime"),
    sum = performanceTime.querySelector("#sumTime"),
    sumWrapper = performanceTime.querySelector(".sum");

  const self = {
    setTotalWebscoketConenctions: (total) =>
      (totalWebsocketConnections.innerHTML = total),

    getTotalWebscoketConenctions: () => totalWebsocketConnections.innerHTML,

    resetTotalWebscoketConenctions: () =>
      (totalWebsocketConnections.innerHTML = 0),

    setTotalJobs: (total) => (totalJobs.innerHTML = total),

    getTotalJobs: () => totalJobs.innerHTML,

    resetTotalJobs: () => (totalJobs.innerHTML = 0),

    time: {
      set: (data) => {
        if (data.start) start.innerHTML = data.start;
        if (data.end) end.innerHTML = data.end.toFixed(0);
        if (data.sum) {
          sum.innerHTML = data.end - data.start;
        } else {
          if (data.start && data.end) {
            sum.innerHTML = data.end - data.start;
          }
        }
      },
      get: () => {
        return {
          start: start.innerHTML,
          end: end.innerHTML,
          sum: sum.innerHTML,
        };
      },
      reset: () => {
        start.innerHTML = 0;
        end.innerHTML = 0;
        sum.innerHTML = 0;
      },
    },

    resetAll: () => {
      self.time.reset();
      self.resetTotalJobs();
      self.resetTotalWebscoketConenctions();
    },

    getAll: () => {
      return {
        time: self.time.get(),
        websocket: {
          total: self.getTotalWebscoketConenctions(),
        },
        jobs: {
          total: self.getTotalJobs(),
        },
      };
    },
  };
  return self;
};

export const reloadDebug = () => {
  const debugs = window.superflow.monitor.debug;
  const debugSection = document.getElementById("debug-section");
  const noData = debugSection.querySelector(".noData");
  const clearbtn = debugSection.querySelector(".titleBtn");
  var debugOutput = debugSection.querySelector("#monitor__output");
  if (debugs) {
    clearbtn.style.display = "block";
    noData.style.display = "none";
    debugOutput.innerHTML = "";
    debugs.map((dbg) => {
      debugOutput.innerHTML += dbg;
    });
    debugOutput.scrollTop = debugOutput.scrollHeight;
  } else {
    debugOutput.innerHTML = "";
    noData.style.display = "flex";
    clearbtn.style.display = "none";
  }
};
export const newDebug = (msg, status = "success") => {
  const debugSection = document.getElementById("debug-section");
  const noData = debugSection.querySelector(".noData");
  var debugOutput = debugSection.querySelector("#monitor__output");
  noData.style.display = "none";
  debugOutput.style.display = "block";

  window.superflow.monitor.debug.push(`
        <div class="log debug ${status}">
        <span class="logTime">${logTime()}:</span> ${msg}
        </div>
    `);
  localStorage.setItem(
    "monitor.debug",
    JSON.stringify(window.superflow.monitor.debug)
  );
  return reloadDebug();
  // return true;
};

export const clearDebug = () => {
  const debugSection = document.getElementById("debug-section");
  var debugOutput = debugSection.querySelector("#monitor__output");
  const noData = debugSection.querySelector(".noData");
  debugOutput.innerHTML = "";
  window.superflow.monitor.debug = [];
  noData.style.display = "flex";
  debugOutput.style.display = "none";
  localStorage.setItem("monitor.debug", null);
};

export const localDebugs = () => {
  const debugs = localStorage.getItem("monitor.debug");
  return debugs ? debugs : false;
};

export const mergeLocalDebugs = () => {
  const debugs = localDebugs();
  if (debugs) {
    window.superflow.monitor.debug = JSON.parse(debugs);
    reloadDebug();
  }
};

export const outputConsole = () => {
  var output = document.getElementById("output"),
    noData = output.querySelector(".noData"),
    content = output.querySelector("#output-content");
  const self = {
    clear: () => {
      content.innerHTML = "";
      content.style.display = "none";
      noData.style.display = "flex";
    },
  };
  return self;
};

export const logConsole = () => {
  var output = document.getElementById("logSection"),
    noData = output.querySelector(".noData"),
    content = output.querySelector("#log-content");
  const self = {
    new: (title, record, status = "default", blink = false) => {
      const ele = document.createElement("div");
      ele.setAttribute("class", `log_list ${status} ${blink ? 'blink-' + blink : ""}`);
      ele.innerHTML = title;
      window.superflow.monitor.logs.push({
        title: title,
        record: record,
      });
      self.reload();
    },
    reload: () => {
      self.show();
    },
    show: () => {
      content.style.display = "block";
      noData.style.display = "none";
    },
    reset: () => {
      content.innerHTML = "";
      content.style.display = "none";
      noData.style.display = "flex";
    },
  };
  return self;
};

import { newDebug } from "./monitor";
export function enableDisablePort() {}

export const handleSubmit = (e) => {
  // loadCompiler();
  e.preventDefault();
  const wrapper = document.getElementById("wrapper");
  wrapper.classList.add("onload");

  const select = document.getElementById("select");
  const results = document.getElementById("results");

  results.innerHTML = "";
  results.style.color = "black";
  const event = localStorage.getItem("event"),
    shop = localStorage.getItem("store");
  newDebug(
    `Fireing action ${
      event ? "<code>" + event + "</code>" : ""
    } on shop <code>${shop}</code>...`,
    "default"
  );
  setTimeout(() => {
    switch (select.value) {
      case "on-page-load-alert-1":
        results.innerHTML = "YES";

        break;
      default:
        const output =
          "<b>Superflow Playground Batch</b> ist currently under development! Superflow has been temporarily disabled!";

        results.innerHTML = '<div class="alert-warning">' + output + "</div>";
        newDebug(output, "danger");
        break;
    }
    //   loadCompiler(false);
    wrapper.classList.remove("onload");
  }, 500);

  return false;
};

import { events } from "../data/events";
import Execution from "../events/Execution";
import FireEvent from "../events/FireEvent";
import Header from "../components/sections/Header";
import Queue from "../components/sections/Queue";
import Debug from "../components/sections/Debug";
import Logs from "../components/sections/Logs";
import LogDetail from "../components/sections/LogDetail";
import Performance from "../components/sections/Performance";
import Loader from "../components/Loader";
import Copyright from "../components/Copyright";
import DisableBanner from "../components/DisableBanner";
import { newDebug } from "../functions/monitor";

import { loadCompiler } from "../functions/load";


const Home = () => {
  const change = (e) => {
    // e.target.setAttribute('disabled', true)

    let value = e.target.value,
      cmd = document.getElementById("cmds");
    if (value.length <= 0 || value === "") {
      cmd.style.display = "none";
      newDebug("Deselect action", "default");
      return;
    }

    let eles = document.getElementsByClassName("fieldElements");
    Object.values(eles).map((ele) => {
      ele.style.display = "none";
    });

    events.map((event) => {
      if (value == event.id) {
        var ele = document.getElementById(event.onSelectShow);
        if (ele) {
          cmd.style.display = "block";
          ele.style.display = "block";
          localStorage.setItem("event", event.command);
          newDebug(
            `selecting action <code>${event.command}</code> [<code>${value}</code>]`,
            "default"
          );
        } else {
          cmd.style.display = "none";
          localStorage.setItem("event", null);
        }
      }
    });
  };

  return (
    <>
      <div className="page">
        <div className="wrapper" id="wrapper">
          <Loader />
          <Header />
          <div className="sections">
            <DisableBanner />

            <div className="section-part">
              <div className="output-title">Input</div>

              <div className="section-content">
                <form className="input-form">
                  <div className="formCont">
                    <div className="fFields">
                      <div>
                        <label className="label">Select action</label>
                        <select id="select" className="field" onChange={change}>
                          <option value="">Select action</option>
                          {events.map((cmd, k) => (
                            <option value={cmd.id} key={k}>
                              {cmd.command}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="cmds" id="cmds">
                        <Execution />
                        <FireEvent />
                      </div>
                    </div>
                  </div>
                </form>
                {/* <button type="submit" className="submitButton">
                Fire event
              </button> */}
              </div>
            </div>

            <div className="section-part">
              <div className="output" id="output">
                <div className="output-title">Output</div>
                <div className="section-content" id="results">
                  <div className="noData" style={{ height: "100%" }}>
                    No output availabe
                  </div>
                  <div id="output-content"></div>
                </div>
              </div>
            </div>

            <Queue />

            <div className="section-part results-section debug-section">
              <Performance />
              <Debug />
              <Logs />
            </div>
          </div>{" "}
          {/* Wrapper end */}
          {/* <Copyright /> */}
        </div>
      </div>
      <LogDetail/>
    </>
  );
};

export default Home;

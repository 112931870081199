import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DOMReady } from './functions/DOM'
import './index.css'

import Home from "./pages/Home";
import Handler from "./pages/Handler";

const App = () => {
  DOMReady()
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/handler" exact element={<Handler/>} />
      </Routes>
    </Router>
  );
};

export default App;

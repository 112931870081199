import React from "react";

function Logs() {
  return (
    <div className="innen-section logs-section" id="logSection">
      <div className="output-title">LOGS</div>
      <div className="noData" style={{ height: "100%" }}>
        No logs availabe
      </div>
      <div id="log-content"></div>
    </div>
  );
}

export default Logs;

import React from "react";

function Logo() {
    return (
        <div className="header-section logo">
        <img
          src="https://admorris.pro/wp-content/uploads/2018/10/logo_Positiv_retina.png"
          alt="admorris logo"
          width={40}
        ></img>
        <div>
          <h3>Superflow Playground</h3>
          <p style={{fontSize: '12px'}}>
            v1.0.0
            {' ~ '}
            Powered by {' '}
            <a href="https://admorris.com" target={'_blank'}>admorris</a>
          </p>
        </div>
      </div>
    )
}

export default Logo
import { getLocalStore } from "./localstorage";
import { shops } from "../data/shops";

export const checkDisableBanner = () => {
    var st = Object.values(shops),
    stores = Object.values(st),
    localStore = getLocalStore(),
    banner = document.getElementById("disable-banner")

  stores.map(store => {
    if (store.url == localStore) {
      document.getElementById('executeBtn').removeAttribute('disabled')
      banner.style.display = 'none' 
    }
  })
}
import React from "react";
import { shops } from "../../data/shops";
import { getLocalStore, settLocalStore } from "../../functions/localstorage";
import { newDebug } from "../../functions/monitor";
import { handleSubmit } from "../../functions/state";
import { execute } from '../../functions/execute'

function Center() {
  var st = Object.values(shops),
    stores = Object.values(st);

  const selectStore = (e) => {
    var store = e.target.value,
      banner = document.getElementById("disable-banner"),
      wrapper = document.getElementById("wrapper"),
      executeBtn = document.getElementById("executeBtn"),
      loaderText = document.getElementById("loaderText");

    if (store.length <= 0) {
      wrapper.classList.remove("onload");
      banner.style.display = "flex";
      loaderText.innerHTML = "Compiling..";
      newDebug(`reset SuperFlow playground..`);
      executeBtn.setAttribute("disabled", true);
      settLocalStore(null);
      return;
    }
    loaderText.innerHTML = "Setting store up..";
    banner.style.display = "none";
    wrapper.classList.add("onload");

    setTimeout(() => {
      settLocalStore(store);
      banner.style.display = "none";
      wrapper.classList.remove("onload");
      loaderText.innerHTML = "Compiling..";
      newDebug(`setting shop to <code>${store}</code>`);
      newDebug(`making connection to shop...`, "default");
      setTimeout(() => {
        newDebug(`checking connection tunnel...`, "default");
      }, 1000);
      setTimeout(() => {
        newDebug(
          `connection failed, seems currently [playground connection module] is disabled.`,
          "warning"
        );
      }, 1250);
      executeBtn.removeAttribute("disabled");
    }, 750);
  };

  const select = () => {
    var banner = document.getElementById("disable-banner"),
      hasLocal = getLocalStore(),
      banner = document.getElementById("disable-banner");
    return (
      <div className="shopSelector">
        {/* <label style={{ marginBottom: "-5px" }}>Select shop</label> */}
        <select
          onChange={selectStore}
          className="storeSelect"
          defaultValue={hasLocal}
        >
          <option value={""}>Select store</option>
          {stores.map((store, key) => (
            <option key={key} value={store.url}>
              {store.url}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const driver = () => {
    return (
      <div className="shopDriver">
        <select>
          <option value={""} disabled>
            select shop provider
          </option>
          <option defaultValue={true}>Shopify</option>
          <option disabled value={"shopware"}>
            Shopware [coming soon]
          </option>
          <option disabled value={"WooCommerce"}>
            WooCommerce [coming soon]
          </option>
          <option disabled value={"PrestaShop"}>
            PrestaShop [coming soon]
          </option>
          <option disabled value={"Magento"}>
            Magento [coming soon]
          </option>
          <option disabled value={"SquareSpace"}>
            SquareSpace [coming soon]
          </option>
          <option disabled value={"Volusion"}>
            Volusion [coming soon]
          </option>
          <option disabled value={"3dCart"}>
            3dCart [coming soon]
          </option>
          <option disabled value={"jtl"}>
            JTL [coming soon]
          </option>
          <option disabled value={"wix"}>
            WIX [coming soon]
          </option>
        </select>
      </div>
    );
  };

  return (
    <div className="header-section center">
      <div className="header-title">Execute Modules</div>
      <div className="header-content">
        <ul className="header-execute-list">
          <li>{select()}</li>
          <li>{driver()}</li>
          <li>
            <button
              className="executebtn"
              id="executeBtn"
              title="execute"
              onClick={execute}
              // disabled
            >
              <img src="https://cdn-icons-png.flaticon.com/512/189/189638.png"></img>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Center;

export const queues = {
  cart: [
    {
      id: "CREATE",
      event: "CREATE",
    },
    {
      id: "REMOVE",
      event: "REMOVE",
    },
    {
      id: "UPDATE",
      event: "UPDATE",
    },
    {
      id: "SHIPPING",
      event: "SHIPPING",
    },
    {
      id: "PRODUCTS",
      event: "PRODUCTS",
    },
    {
      id: "PRODUCT_COUNT",
      event: "PRODUCT_COUNT",
    },
    {
      id: "SUBTOTAL",
      event: "SUBTOTAL",
    },
    {
      id: "TOTAL",
      event: "TOTAL",
    },
  ],
  order: [
    {
      id: "CREATE",
      event: "CREATE",
    },
    {
      id: "REMOVE",
      event: "REMOVE",
    },
    {
      id: "UPDATE",
      event: "UPDATE",
    },
    {
      id: "COUNT_ALL_ORDERS",
      event: "COUNT_ALL_ORDERS",
    },
  ],

  checkout: [
    {
      id: "PAID",
      event: "PAID",
    },
  ],

  customer: [
    {
      id: "REGISTER",
      event: "REGISTER",
    },
    {
      id: "LOGIN",
      event: "LOGIN",
    },
  ],
};

import React from "react";

function DisableBanner() {
  return (
    <div className="disable-banner" id="disable-banner">
      <img src="https://www.overflow.design/assets/img/ec-illustrations/ec-write-a-blog-post.jpg"></img>
      Please choose a store first
    </div>
  );
}

export default DisableBanner;

// const endpoint = "https://api.superflow.app";
const endpoint = "http://127.0.0.1:8787";
const websocket = 'https://api.superflow.app/_websocket'

export const post = async (url, data = {}) => {
  var Http = new XMLHttpRequest();
  data.key = 'ebaa0c92bc0b1d66be0d7d266a128411'
  var data = JSON.stringify(data);
  Http.open("POST", `${endpoint}/${url}`);
  Http.setRequestHeader("Accept", "application/json");
  Http.send(data);

  Http.onreadystatechange = (e) => {
    return Http.responseText;
  };

  let res;
  const promised = new Promise((r) => (res = r));
  Http.onreadystatechange = function () {
    if (Http.readyState == 4 && Http.status == 200) {
      res(Http.responseText);
    }
  };
  return promised;
};
import React from "react";

function Right() {
  return (
    <div className="header-section right">
      <div className="header-title">Helper links</div>
      <div className="header-content">
        <ul className="headersLinks">
          <li>
            <a href="https://admorris.pro" target={'_blank'}>admorrisPro</a>
          </li>
          <li>~</li>
          <li>
            <a href="#" target={'_blank'}>GitHub</a>
          </li>
          <li>~</li>
          <li>
            <a href="#" target={'_blank'}>docs</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Right;

import React from "react";
import { clearDebug } from "../../functions/monitor";

function Debug() {
  // const clear = () => clearDebug()
  return (
    <div className="innen-section debug-section" id="debug-section">
      <div className="output-title">
        Debug
        <button
          className="titleBtn"
          style={{ display: "none" }}
          onClick={clearDebug}
        >
          Clear
        </button>
      </div>
      <div className="innen-content no-padding" style={{marginTop: '-.5rem !important'}}>
        <div className="noData">No logs debug availabe</div>
        <div id="monitor__output"></div>
      </div>
    </div>
  );
}

export default Debug;

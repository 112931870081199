export const events = [
  {
    id: 'fire-event-on-shop',
    command: 'Fire event on Shop',
    onSelectShow: 'fire-event-on-shop',
    callback: null,
  },
  {
    id: 'send-execution',
    command: 'Inject code to Shop',
    onSelectShow: 'execution',
    callback: null,
  },
  // {
  //   id: 'get-shop-info',
  //   command: 'Get Shop info',
  //   onSelectShow: 'get-shop-info',
  //   callback: null,
  // },
  // {
  //   id: 'get-shop-theme-info',
  //   command: 'Get Shop Theme info',
  //   onSelectShow: 'get-shop-theme-info',
  //   callback: null,
  // },
];
